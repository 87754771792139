<script setup>
// TODO Add the basic SEO information for each page: Title, Meta Description

// ZigZag required scripts and styles
const config = useRuntimeConfig()

const { version } = useZigZag()
const { data, refresh, error } = await version(config.public.zigzagUrl)

const time = data.value ? data.value.t : 0;
const sha = data.value ? data.value.sha : 'versionnotfound';

onMounted(() => {
  
  if (typeof angular === 'undefined' || angular === null) {
    useHead({
      script: [        
        {
          src: `${config.public.zigzagUrl}/${config.public.zigzag3rdParty}?sha=${sha}&t=${time}`,
          body: true,          
          onload: () => {
            const script = document.createElement("script");
            script.src = `${config.public.zigzagUrl}/${config.public.zigzagPulseApp}?sha=${sha}&t=${time}`;
            script.id = 'pulse-app-script';
            document.body.appendChild(script);             
          }
          
        }
      ],
      link: [    
        {
          rel: 'stylesheet',
          href: `${config.public.zigzagUrl}/styles/pulseapp.css?sha=${sha}&t=${time}`
        }
      ]
    });
  }
  else {
    // Forcing a page reload to unmount a previous angularjs app 
    angular.reloadWithDebugInfo();
  }
})


const route = useRoute()
// Update UTM cookies with parameters if they exist in the URL
for (let param in route.query) {
  let options = {
    maxAge: 864000 // 10 days to expire
  }
  let cookieVal = useCookie(param, options)
  cookieVal.value = route.query[param]
}

</script>
<template>
  <LayoutNavBar />
  <main>
    <NuxtPage />
  </main>
  <LayoutFooterNavigation />
  <LayoutCookieWarning />
</template>